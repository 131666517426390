import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import { useEffect } from 'react';

import { AppContextWrapper } from '../util/context';
import { LocationProvider } from '../util/LocationProvider';

import { useRouteChange } from '../util/hooks';
import { GTMPageView, gtag } from '../util/googleTagManager';

import '../styles/index.scss';

/* If in development mode, inject axe accessibility tools. */
if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
    const ReactDOM = require('react-dom');
    const axe = require('@axe-core/react');

    axe(React, ReactDOM, 1000, {
        runOnly: ['best-practice', 'wcag2a', 'wcag2aa']
    });
}

export const apolloClient = new ApolloClient({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
    cache: new InMemoryCache()
});


function App({ Component, pageProps, err }) {
    /* Trigger a page view on in-app routing */
    useRouteChange(url => setTimeout(() => {
        GTMPageView(url);

        window.dataLayer && window.dataLayer.push({'event': 'optimize.activate'});
    }));

    useEffect(() => {
        window.dataLayer && window.dataLayer.push({'event': 'optimize.activate'});
        
        gtag('event', 'optimize.callback', {
            callback: (value, name) => {
                // console.log(name + ': ' + value);
            }
        });
    }, []);

    return (
        <AppContextWrapper>
            <ApolloProvider client={apolloClient}>
                <LocationProvider>
                    <Component {...pageProps} err={err} />
                </LocationProvider>
            </ApolloProvider>
        </AppContextWrapper>
    );
}

export default App;
