import React, { useState, createContext, useEffect } from "react";
import { useRouter } from 'next/router';

const LocationContext = createContext(null);

// update default location if they're coming in from an external link

function LocationProvider({ children }) {
    const haveWindow = (typeof window !== 'undefined');

    const router = useRouter();

    // If we have the window preserve URL params.
    let savedParams = {};
    if (haveWindow) {
        const skip = [
            'age',
            'time',
            'date',
            'sport',
            'day',
            'season',
            'page',
        ];

        savedParams = localStorage.getItem('params') ? JSON.parse(localStorage.getItem('params')) : {};
        for (let param in router?.query) {
            if (!skip.includes(param)) {
                savedParams[param] = router.query[param];
            }
        }

        localStorage.setItem('params', JSON.stringify(savedParams));
    }
    const [params, setParams] = useState(savedParams);

    function zohoReload() {
        if (haveWindow && !window.location.search.includes('zohoReload')) {
            if (Object.keys(params) !== 0) {
                let reloadParams = new URLSearchParams({
                    ...params,
                    zohoReload: 1,
                }).toString();
                let path = router.asPath.includes('?') ? `${router.asPath}&${reloadParams}` : `${router.asPath}?${reloadParams}`;
                window.location.href = path;
            }
        }
    }

    let centers = {
        'chelsea':  14,
        'brooklyn': 13,
        'stamford': 18,
    };

    let slugs = {
        'chelsea':  '/new-york/chelsea',
        'brooklyn': '/new-york/brooklyn',
        'stamford': '/connecticut/stamford',
    };

    let labels = {
        'chelsea':  'Chelsea, NY',
        'brooklyn': 'Brooklyn, NY',
        'stamford': 'Stamford, CT',
    };

    const getSlug = (location) => {
        return slugs?.[location];
    }
    const getLabel = (location) => {
        return labels?.[location];
    };
    const getCenter = (location) => {
        return centers?.[location];
    };

    const getLocaleFromPath = (path) => {
        //let haveWindow = (typeof window !== 'undefined');
        if (path=='/') return false;
        for (let location in slugs) {
            let slug = slugs[location];
            if (path.includes(slug)) {
                return location;
            }
        }
        return false;
    };


    // Is the location set in storage?
    let sportsLoc = haveWindow ? localStorage.getItem('location-sports') : false;
    let eventsLoc = haveWindow ? localStorage.getItem('location-events') : false;

    if (haveWindow) { // Client only.
        
        sportsLoc = sportsLoc=='null' ? false : sportsLoc;
        eventsLoc = eventsLoc=='null' ? false : eventsLoc;

        // Is this a direct link to location content?
        let pathLocale = getLocaleFromPath(window.location.pathname);
        if (pathLocale) { // Location is inferred in the current URL, default to that.
            sportsLoc = pathLocale;
            eventsLoc = pathLocale;
        }
    }

    const defaultLocationSports = sportsLoc ? sportsLoc : 'chelsea';
    const defaultLocationEvents = eventsLoc ? eventsLoc : 'chelsea';

    const [sportsLocation, setSportsLocation] = useState(defaultLocationSports);
    const [sportsLabel, setSportsLabel] = useState(getLabel(defaultLocationSports));

    const [eventsLocation, setEventsLocation] = useState(defaultLocationEvents);
    const [eventsLabel, setEventsLabel] = useState(getLabel(defaultLocationEvents));

    if (haveWindow) {
        localStorage.setItem('location-sports', defaultLocationSports);
        localStorage.setItem('location-events', defaultLocationEvents);
    }

    const [placeholders, setPlaceholders] = useState([]);


    function setLocation(site, location, redirect=true) {
        switch (site) {
            case 'sports':
                setSportsLocation(location);
                setSportsLabel(getLabel(location));
                break;

            case 'events':
                setEventsLocation(location);
                setEventsLabel(getLabel(location));
                break;

            default: break;
        }

        if (typeof window !== 'undefined') { // Client only.
            window.localStorage.setItem(`location-${site}`, location); // Update new location in local storage.
            if (redirect) { 
                // If redirect path is passed in, use that, otherwise use the current page path.
                let path = (typeof redirect == 'string') ? redirect : `${window.location.pathname}${window.location.search}${window.location.hash}`; 
                if (path != '/') { // Skip formatting path for home page.
                    for (let slug in slugs) { // Remove old location prefix from path.
                        path = path.replace(slugs[slug], '');
                    }

                    path = slugs[location] + path; // Add new location's prefix to path. 
                }
                window.location.href = path; // Redirect.
            }
        }
    }


    function dropdownLocation(site, locale) {
        let slug = slugs[locale];
        if (process?.env?.NEXT_PUBLIC_SUBSITE != site) {
            window.location.href = `https://${site}.chelseapiers.com${slug}/welcome`;
        } else {
            setLocation(site, locale);
        }
    }

    
    function getLogo(site, mobile=false, ext='png') {

        let logo = site;
        let location = getLocation(); 
        if (location) {
            logo += `-${location}`;
        }
        if (mobile) {
            logo += '-mobile';
        }

        logo += `.${ext}`;

        return `/logos/${logo}`;
    }


    function getWhiteLogo(site, mobile=false) {
        let logo = site;
        let location = getLocation(); 
        logo += `-${location}`;

        if (mobile) {
            logo += '-mobile';
        }

        logo += `.svg`;

        return `/logos/${logo}`;
    }


    function applyFilters(data) {
        if (typeof data != 'object') {
            return data;
        }
        
        // Convert the block JSON back into a string to swap placeholders.
        let dataJSON = JSON.stringify(data);

        let locale = getLocation();
        for (var i=0; i<placeholders?.length; i++) {
            let ph = placeholders[i];

            if (ph?.locale == locale) {
                // Placeholder keys are the GraphQL field name upper case.
                for (const [k,v] of Object.entries(ph)) {
                    let token = '{{' + k.toUpperCase() + '}}';
                    dataJSON = dataJSON.replace(token, v);
                }         
                break;
            }   
        }


        // Convert back into an object.
        return JSON.parse(dataJSON);
    }


    function setSitePlaceholders(ph) {
        for (let i=0; i<ph?.length; i++) {
            let subsite = ph[i]?.node?.title.toLowerCase();
            if (subsite == process?.env?.NEXT_PUBLIC_SUBSITE) {
                setPlaceholders(ph[i]?.node?.placeholders?.placeholders);
            }
        }
    }


    function getLocation() {
        switch (process?.env?.NEXT_PUBLIC_SUBSITE) {
            case 'sports': return sportsLocation ? sportsLocation : 'chelsea';
            case 'events': return eventsLocation ? eventsLocation : 'chelsea';
            default: break;
        }
        return false;
    }


    const localeLink = (link) => {
        if (process?.env?.NEXT_PUBLIC_USE_LOCATIONS!='1' || !link) return link;

        let locale = getLocation();
        let slug = getSlug(locale);

        if (!link.includes(slug) && link!='/' && !link.includes('http')) {
            link = link.startsWith('/') ? link : `/${link}`;
            return slug + link;
        } else return link;
    }


    function isLocalePath(path) {
        let haveWindow = (typeof window !== 'undefined');
        if (!haveWindow) return true;

        if (path == '/') return true;

        for (let location in slugs) {
            let slug = slugs[location];
            if (path.includes(slug)) {
                return true;
            }
        }
        
        return localeLink(path);
    }


    const hideMenu = (item) => {
        if (typeof window == 'undefined') return false;

        const location = getLocation();
        let limitDisplay = item?.locationLimit?.limitdisplay || false;
        const displayLocations = item?.locationLimit?.displaylocations || [];

        if (displayLocations.includes('all') || displayLocations.length==0) {
            limitDisplay = false;
        }

        if (limitDisplay) {
            limitDisplay = !displayLocations.includes(location);
        }

        return Boolean(limitDisplay);
    };

    function hideBlock(blockAttributes, locale=false, windowCheck=true) {
        if (typeof window == 'undefined' && windowCheck) return false; // Server always renders.

        const location = locale ? locale : getLocation();

        // Check if the block is set to limited to display by location.
        let limitDisplay = blockAttributes?.limitDisplay || false;
        const displayLocations = blockAttributes?.displayLocations || [];

        if (displayLocations.includes('all') || displayLocations.length==0) {
            limitDisplay = false;
        }
        if (limitDisplay) {
            limitDisplay = !displayLocations.includes(location);
        }
        return Boolean(limitDisplay);
    }


    // Return first metatag block that matches location rules.
    function getMetatags(blocks, locale) {
        let metatags = {};
        blocks.forEach((blockJSON) => {
            try {
                let block = JSON.parse(blockJSON?.attributesJSON);
                if (block?.name == 'acf/metadata-tags') { // Pull out metatag components.
                    if (!hideBlock(block?.data, locale, false) || process?.env?.NEXT_PUBLIC_USE_LOCATIONS=='0') {
                        metatags = block;
                    }
                }
            } catch (e) { console.error(e); }
        });
        return metatags;
    }

    const props = {
        sportsLocation,
        eventsLocation,
        dropdownLocation,
        sportsLabel,
        eventsLabel,
        localeLink,
        setLocation,
        getLocation,
        hideMenu,
        hideBlock,
        getLabel,
        placeholders,
        setSitePlaceholders,
        applyFilters,
        getLogo,
        getWhiteLogo,
        isLocalePath,
        getLocaleFromPath,
        getCenter,
        getMetatags,
        zohoReload,
    };

    return (
        <LocationContext.Provider value={{ ...props }}>
            {children}
        </LocationContext.Provider>
    );
}

export { LocationProvider, LocationContext };
