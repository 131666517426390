import { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export function AppContextWrapper({ children }) {
    const [dark, setDarkMode] = useState(false);
    const [hideNav, setHideNav] = useState(false);
    const [expandHours, setExpandHours] = useState(false);

    let sharedState = {
        dark: dark,
        expandHours: expandHours,
        hideNav: hideNav,
        setHideNav: setHideNav,
        toggleExpand: (value) => setExpandHours(value),
        toggleDarkMode: () => setDarkMode(!dark)
    };

    return (
        <AppContext.Provider value={sharedState}>
            {children}
        </AppContext.Provider>
    );
}

export function useAppContext() {
    return useContext(AppContext);
}
