import Router from 'next/router';
import { useEffect, useState } from 'react';

/*
 * Debounce hook from: https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
 */
export const useDebounce = function (value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    return debouncedValue;
}

/*
 * On each Next router transition, run callback.
 */
export const useRouteChange = function (callback) {
    useEffect(() => {
       
        const handleRouteChange = () => {
            callback()
        };

        Router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            Router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);
};


/*
 * If a user clicks outside the ref element, run callback.
 */
export const useClickOutside = function (ref, callback) {
    const onClick = (ev) => {
        if (ref.current && !ref.current.contains(ev.target)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('click', onClick);

        return () => {
            document.removeEventListener('click', onClick);
        };
    }, []);
};
